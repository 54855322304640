import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'OptionMenu',
    component: () => import('../pages/Home.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../pages/Home.vue')
  },
  {
    path: '/ayuda',
    name: 'ayuda',
    component: () => import('../pages/Ayuda.vue')
  },
  {
    path: '/terminos-condiciones',
    name: 'terminosCondiciones',
    component: () => import('../pages/TerminosCondiciones.vue')
  },
  {
    path: '/tarjeta',
    name: 'tarjeta',
    component: () => import('../pages/Tarjeta.vue')
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../pages/TarjetaApp.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../pages/FAQS.vue')
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('../pages/NotFound.vue')
}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
