import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Menu from '@/assets/icons/menu.vue';
import Email from '@/assets/icons/email.vue';
import Portafolio from '@/assets/icons/portafolio.vue';
import Telefono from '@/assets/icons/telefono.vue';
import CheckboxNo from '@/assets/icons/checkboxno.vue';
import CheckboxSi from '@/assets/icons/checkboxsi.vue';
import Ubicacion from '@/assets/icons/ubicacion.vue';
import Ubicacion2 from '@/assets/icons/ubicacion2.vue';
Vue.use(Vuetify);

export default new Vuetify({
    icons: {

        values:{
          menuHamburguesa: { // name of our custom icon
            component: Menu // our custom component
          },
          email: { // name of our custom icon
            component: Email // our custom component
          },
          telefono: { // name of our custom icon
            component: Telefono // our custom component
          },
          portafolio: { // name of our custom icon
            component: Portafolio // our custom component
          },
          checkboxNo: { // name of our custom icon
            component: CheckboxNo // our custom component
          },
          checkboxSi: { // name of our custom icon
            component: CheckboxSi // our custom component
          },
          ubicacion:{
            component:Ubicacion
          },
          ubicacion2:{
            component:Ubicacion2
          }
        }
       
      },
});
